import { setLanguage } from "config/language";
import Banner from "../common/Banner";
import BazAINavigation from "../common/BazAINavigation";
import { useEffect, useState } from "react";
import FooterV2 from "../common/FooterV2";
import BazAIHelmet from "../common/BazAIHelmet";
import { baseUrl } from "config/apiUrl";
import Partner from "../common/Partner";
import PricePlan from "../common/PricePlan";
import ProductFacilites from "../common/ProductFacilities";
import { distributorData } from "./distributordata";

export default function LandingForDistributorManagement() {
	const [scrolling, setScrolling] = useState(false);
	const [scrollTop, setScrollTop] = useState(0);

	useEffect(() => {
		function onScroll() {
			const currentPosition = document.documentElement.scrollTop;
			setScrolling(currentPosition > 150);
			setScrollTop(currentPosition);
		}

		window.addEventListener("scroll", onScroll);
		return () => window.removeEventListener("scroll", onScroll);
	}, [scrollTop]);

	if (localStorage.getItem("lan") === null) {
		setLanguage();
	}

	return (
		<>
			<BazAIHelmet
				title='Distributor Management'
				description='Easily handle your distributor.'
				image='/assets/images/LogoAI.png'
				url={`${baseUrl.webUrl}/delivery`}
			/>
			<BazAINavigation
				scrolling={scrolling ? "isScrolling" : ""}
				from='mainLanding'
			/>
			<div className='container'>
				<Banner
					headline='Hotel'
					bannerImage='/assets/images/distributor-banner.jpeg'
					from='health'
				/>
			</div>
			<ProductFacilites facilities={distributorData} from='food' />
			<div className='my-16'>
				<Partner />
			</div>
			<PricePlan from='food' />
			<FooterV2 />
		</>
	);
}
