import { useMediaQuery } from "react-responsive";
import Mobile from "./Mobile";
function MainRoutes() {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 599px)" });

	// return <>{isTabletOrMobile ? <Mobile /> : ""}</>;
	// return <>{isTabletOrMobile ? <Mobile /> : <Web />}</>;
	return <Mobile />;
}

export default MainRoutes;
