import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BlogData from "services/Blog";
import moment from "moment";
import "./style.scss";
import parse from "html-react-parser";
import FooterV2 from "../common/FooterV2";
import BazAINavigation from "../common/BazAINavigation";

export default function SingleBlogPage() {
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  let { id } = useParams();
  const [singleBlog, setSingleBlog] = useState({});
  const [loading, setLoading] = useState(false);
  // useEffect(() => {
  // 	function onScroll() {
  // 		// let currentPosition = window.pageYOffset;
  // 		// document.documentElement.scrollTop; // or use document.documentElement.scrollTop;
  // 		let currentPosition = document.documentElement.scrollTop; // or use document.documentElement.scrollTop;
  // 		if (currentPosition > 150) {
  // 			setScrolling(true);
  // 		} else {
  // 			setScrolling(false);
  // 		}
  // 		setScrollTop(currentPosition);
  // 	}

  // 	window.addEventListener("scroll", onScroll);
  // 	return () => window.removeEventListener("scroll", onScroll);
  // }, [scrollTop]);

  const getSingleBlog = async () => {
    setLoading(true);
    let response = await BlogData.singleBlog(id);

    if (response.status === 200) {
      setLoading(false);
      setSingleBlog(response.results);
    } else {
      setLoading(false);
    }
  };
  // const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    getSingleBlog();
    // document.documentElement.scrollTo({
    // 	top: 0,
    // 	left: 0,
    // 	behavior: "instant",
    // });
  }, [id]);

  return (
    <section>
      <BazAINavigation
        blogPost={true}
        // scrolling={scrolling ? "isScrolled" : ""}
        bg={true}
      />
      {!loading ? (
        <div className="pt-[100px] sm:pt-[120px] pb-24">
          <div className="container">
            <div className="m-auto w-full lg:w-8/12">
              <img
                src={singleBlog?.thumbnail_image}
                alt="thumbnail"
                className="pb-10 w-full"
              />
              <div>
                <h2 className="lg:text-40 md:text-32 text-22 text-black-1000 font-bold pb-3">
                  {singleBlog?.title}
                </h2>
                <p className="text-12 sm:text-16 font-normal text-808080 pb-5">
                  {moment(singleBlog.createdAt, "YYYY-MM-DD").format(
                    "DD MMM, YYYY"
                  )}
                </p>
                {singleBlog?.content && parse(singleBlog?.content)}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="pt-[100px] sm:pt-[150px] pb-24">
          <div className="m-auto w-full lg:w-8/12">
            <div className="flex h-full justify-center items-center">
              <div className="lds-facebook">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      )}

      <FooterV2 />
    </section>
  );
}
