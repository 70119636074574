export const financingData = [
	{
		id: 1,
		tabTitle: "Seamless Delivery Management",
		userType: "Flexible Payment Solutions",
		description:
			"Offer your customers a range of payment options for smooth and flexible transactions. Our platform supports diverse methods, catering to customer preferences and enhancing satisfaction.",
		banner: "/assets/images/products/financing1.png",
	},
	{
		id: 2,
		tabTitle: "Real Time Order Management",
		userType: "Customer Financing Options",
		description:
			"Provide tailored financing solutions that meet customer needs, boosting their purchasing power and increasing your sales with flexible payment plans.",
		banner: "/assets/images/products/financing2.png",
	},
	{
		id: 3,
		tabTitle: "AI-Driven Delivery Optimization",
		userType: "AI-Driven Loyalty Rewards",
		description:
			"Harness AI to offer personalized discounts and rewards to loyal customers, driving retention and repeat business.",
		banner: "/assets/images/products/financing3.png",
	},
];
