import { setLanguage } from "config/language";
import Banner from "../common/Banner";
import Partner from "../common/Partner";
import PricePlan from "../common/PricePlan";
import BazAINavigation from "../common/BazAINavigation";
import UserFacilites from "../common/UserFacilities";
import { useEffect, useState } from "react";
import FooterV2 from "../common/FooterV2";
import { foodFacilites } from "./foodFacilities";
import BazAIHelmet from "../common/BazAIHelmet";
import { baseUrl } from "config/apiUrl";

export default function LandingForFood() {
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  useEffect(() => {
    function onScroll() {
      let currentPosition = document.documentElement.scrollTop;
      if (currentPosition > 150) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
      setScrollTop(currentPosition);
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);
  if (localStorage.getItem("lan") === null) {
    setLanguage();
  }

  return (
    <>
      <BazAIHelmet
        title="AI Agent for Consumer Goods"
        description="AI Agent for Consumer Goods Industry."
        image="/assets/images/LogoAI.png"
        url={`${baseUrl.webUrl}/c-goods`}
      />
      <BazAINavigation
        scrolling={scrolling ? "isScrolling" : ""}
        from="mainLanding"
      />
      <div className="container">
        <Banner
          headline="Consumer Goods"
          bannerImage="/assets/images/food_banner_2.jpeg"
          from="food"
        />
      </div>
      <UserFacilites facilities={foodFacilites} from="food" />
      <div className="my-16">
        <Partner />
      </div>
      <PricePlan from="food" />
      <FooterV2 />
    </>
  );
}
