import React from "react";
import parse from "html-react-parser";

export default function SingleProductFacility({
	id,
	userType,
	extraLabel,
	description,
	formLink,
	btnLabel,
	banner,
	from,
	parsing,
	description2,
}) {
	return (
		<div
			className={`flex ${
				id % 2 !== 0
					? "lg:flex-row flex-col"
					: "lg:flex-row-reverse flex-col"
			} w-full lg:mb-16 mb-12 relative md:items-center`}>
			<div className='lg:w-[50%] w-full lg:p-5 p-2 flex items-center'>
				<img
					className={`object-cover w-[65%] m-auto`}
					src={banner}
					alt=''
				/>
			</div>
			<div className='lg:w-[50%] w-full lg:p-5 p-2'>
				<h6 className='font-bold text-[#000000] lg:text-[28px] text-20 lg:pb-3 pb-0 pt-3 lg:pt-1 leading-10'>
					{userType}
				</h6>
				<p
					className={`font-medium lg:text-22 text-14  text-[#00000080]  text-left ${
						from === "food" ? "mt-5" : "mt-5"
					}`}>
					{parsing ? parse(description) : description}
				</p>
				{description2 ? (
					<p
						className={`font-medium lg:text-22 text-14  text-[#00000080]  text-left ${
							from === "food" ? "mt-5" : "mt-5"
						}`}>
						{parsing ? parse(description2) : description2}
					</p>
				) : undefined}
			</div>
		</div>
	);
}
