import React from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";

export default function ExperiencesAdvantage() {
	return (
		<div className='container lg:pt-20 pt-14'>
			<h6 className='mb-10 font-bold text-[#000000] lg:text-[30px] text-20  lg:pb-5 pb-0 pt-5 lg:pt-0 text-center'>
				The power of Baz AI applied to
			</h6>
			<div>
				<div className='grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 '>
					<div
						className='bg-white p-5 rounded-[5px] relative'
						style={{ boxShadow: "1px 2px 10px rgba(0,0,0,.1)" }}>
						<h3 className='text-18 font-bold text-black-1000 mb-2'>
							Sourcing
						</h3>
						<p className='text-normal font-14 text-black-500'>
							Identify and qualify new vendors
						</p>
					</div>
					<div
						className='bg-white p-5 rounded-[5px] relative'
						style={{ boxShadow: "1px 2px 10px rgba(0,0,0,.1)" }}>
						<h3 className='text-18 font-bold text-black-1000 mb-2'>
							Onboarding
						</h3>
						<p className='text-normal font-14 text-black-500'>
							Onboard suppliers and keep your vendor data up-to-date
						</p>
					</div>
					<div
						className='bg-white p-5 rounded-[5px] relative'
						style={{ boxShadow: "1px 2px 10px rgba(0,0,0,.1)" }}>
						<h3 className='text-18 font-bold text-black-1000 mb-2'>
							Requisitions
						</h3>
						<p className='text-normal font-14 text-black-500'>
							Manage and centralize your internal requisition process
						</p>
					</div>
					<div
						className='bg-white p-5 rounded-[5px] relative'
						style={{ boxShadow: "1px 2px 10px rgba(0,0,0,.1)" }}>
						<h3 className='text-18 font-bold text-black-1000 mb-2'>
							PO management
						</h3>
						<p className='text-normal font-14 text-black-500'>
							Proactively check in on purchase orders and make sure they
							get to you on time
						</p>
					</div>
					<div
						className='bg-white p-5 rounded-[5px] relative'
						style={{ boxShadow: "1px 2px 10px rgba(0,0,0,.1)" }}>
						<h3 className='text-18 font-bold text-black-1000 mb-2'>
							Multi-Modal
						</h3>
						<p className='text-normal font-14 text-black-500'>
							Use of Natural Texting, Voice or Vision modals to complete
							workflows
						</p>
					</div>
					<div
						className='bg-white p-5 rounded-[5px] relative'
						style={{ boxShadow: "1px 2px 10px rgba(0,0,0,.1)" }}>
						<h3 className='text-18 font-bold text-black-1000 mb-2'>
							AI Ordering
						</h3>
						<p className='text-normal font-14 text-black-500'>
							Let AI take care of your work 24/7
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}
