export const deliveryData = [
	{
		id: 1,
		extraLabel: "Your Challenges",
		userType: "Flawless Delivery Coordination",
		description:
			"Assign drivers with precision for on-time deliveries, offering flexible payment options like cash on delivery and pick-and-pay.",
		banner: "/assets/images/products/delivery1.png",
	},

	{
		id: 2,
		extraLabel: "Our Solutions",
		userType: "Real-Time Order Precision",
		description:
			"Monitor orders in real-time at every stage, ensuring efficiency and enhancing customer satisfaction.",
		banner: "/assets/images/products/delivery2.png",
	},

	{
		id: 3,
		extraLabel: "Call to Action",
		userType: "AI-Powered Delivery Optimization",
		description: `Leverage AI to refine delivery routes and predict arrival times, cutting costs and boosting efficiency. Anticipate delays and streamline logistics for smooth, timely deliveries.`,
		banner: "/assets/images/products/delivery3.png",
	},
];
