import React from "react";
import "./style.scss";
import { motion } from "framer-motion";

export default function Banner({
  headline,
  from,
  bannerImage,
  alreadyHaveTopPadding,
}) {
  const text = headline.split(" ");
  // ! : Previous Image Size
  //? "lg:pt-24 pt-20 lg:h-[350px]"
  //: from === "food"
  //? "lg:pt-24 pt-20 lg:h-[550px]"
  //: "lg:pt-24 pt-20 lg:h-[700px]"
  return (
    <div
      className={`${
        alreadyHaveTopPadding
          ? "lg:pt-24 pt-20 lg:h-[350px]"
          : from === "food"
          ? "lg:pt-24 pt-20 lg:h-[350px]"
          : "lg:pt-24 pt-20 lg:h-[350px]"
      } relative overflow-hidden`}
    >
      <div
      // className={`${
      //   from === "aboutUs"
      //     ? ""
      //     : from === "contactUs"
      //     ? ""
      //     : from === "food"
      //     ? ""
      //     : from === "electronics"
      //     ? ""
      //     : from === "health"
      //     ? ""
      //     : "image-with-filter"
      // }`}
      >
        <img
          className="w-full h-full left-0 block"
          src={bannerImage}
          alt="banner"
        />
      </div>

      {/* {from === "aboutUs" ? (
        ""
      ) : from === "contactUs" ? (
        ""
      ) : from === "food" ? (
        ""
      ) : from === "electronics" ? (
        ""
      ) : from === "health" ? (
        ""
      ) : (
        <div
          className={`absolute ${
            alreadyHaveTopPadding ? "lg:pt-24 pt-16" : "lg:pt-24 pt-16"
          } flex justify-center items-center w-full h-full top-0 left-0`}
        >
          <h6
            className={`text-white ${
              alreadyHaveTopPadding
                ? "lg:text-[50px] text-[30px]"
                : from === "electronics"
                ? "lg:text-[70px] text-[22px]"
                : "lg:text-[80px] text-[30px]"
            } font-bold`}
            style={{
              fontFamily:
                from === "film"
                  ? "Syncopate, sans-serif"
                  : from === "health"
                  ? "Verdana, sans-serif"
                  : from === "electronics"
                  ? "Orbitron, sans-serif"
                  : from === "food"
                  ? "Playfair Display, serif"
                  : "Poppins, sans-serif",
            }}
          >
            {text.map((el, i) => (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  duration: 0.5,
                  delay: i / 10,
                }}
                key={i}
              >
                {el}{" "}
              </motion.span>
            ))}
          </h6>
        </div>
      )} */}
    </div>
  );
}
