export const b2bData = [
	{
		id: 1,
		extraLabel: "Your Challenges",
		userType: "Empower Your Business Decisions",
		description:
			"Boost revenue, cut costs, and achieve sustainability goals with AI-powered insights and predictive models. Unlock endless possibilities in your marketplace operations for optimal outcomes every time.",
		banner: "/assets/images/products/b2b1.png",
	},

	{
		id: 2,
		extraLabel: "Our Solutions",
		userType: "Accelerate Collaboration and Innovation",
		description:
			"Move beyond traditional methods. Shift to a digital marketplace where your data, teams, and partners converge. Collaborate in real-time, visualize trends, and explore multiple scenarios to make faster, smarter decisions.",
		banner: "/assets/images/products/b2b2.png",
	},

	{
		id: 3,
		extraLabel: "Call to Action",
		userType: "AI-Powered Marketplace Intelligence",
		description: `Secure the best deals with instant, real-time pricing insights from a vast supplier network. Simplify procurement with automated workflows, ensuring you get the most value with minimal effort.`,
		banner: "/assets/images/products/b2b3.png",
	},
];
