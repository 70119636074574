import { createSlice } from "@reduxjs/toolkit";
import { getLanguage } from "config/language";
import bangla from "i18n/bn";
import english from "i18n/en";

export const languageSlice = createSlice({
	name: "language",
	initialState: {
		lan:
			(getLanguage() === "en") | (getLanguage() === null) ? english : bangla,
	},
	reducers: {
		selectLan: (state) => {
			if ((getLanguage() === "en") | (getLanguage() === null)) {
				state.lan = english;
			} else {
				state.lan = bangla;
			}
		},
	},
});

// Action creators are generated for each case reducer function
export const { selectLan } = languageSlice.actions;
export const { actions, reducer } = languageSlice;
export default reducer;
