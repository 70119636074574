import React from "react";

export default function ContactEmails() {
  return (
    <div className="flex justify-center mb-20">
      <div className="flex">
        <span className="text-black-1000 lg:text-20 text-14 lg:mr-2 mr-0.5 lg:inline-block hidden">
          Sales :
        </span>
        <a
          href="mailto:sales@ekkbaz.ai"
          className="text-delivered lg:text-20 text-14 lg:mr-5 mr-2 cursor-pointer font-semibold hover:underline"
          target="_blank"
          rel="noreferrer"
        >
          Sales@EkkBaz.ai
        </a>
        <span className="text-808080 lg:text-20 text-14 lg:mr-5 mr-2">|</span>
        <span className="text-black-1000 lg:text-20 text-14 lg:mr-2 mr-0.5 lg:inline-block hidden">
          Help :
        </span>
        <a
          href="mailto:help@ekkbaz.ai"
          className="text-delivered lg:text-20 text-14 lg:mr-5 mr-2 cursor-pointer font-semibold hover:underline"
          target="_blank"
          rel="noreferrer"
        >
          Help@EkkBaz.ai
        </a>

        <span className="text-808080 lg:text-20 text-14">|</span>
        <a
          href="https://wa.me/+6592344538"
          target="_blank"
          rel="noreferrer"
          className="lg:ml-5 ml-2 text-delivered font-semibold hover:underline lg:text-20 text-14"
        >
          Message us
        </a>
      </div>
    </div>
  );
}
