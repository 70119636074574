export const electronicsFacilities = [
  {
    id: 1,
    extraLabel: "Your Challenges",
    userType: "Are you facing these challenges in your procurement?",
    description: {
      line1: `<strong className='font-bold text-black-1000'>Supplier Reliability and Risk Management</strong> <br/> Ensuring supplier reliability and managing risks.`,
      line2: `<strong className='font-bold text-black-1000'>Cost Control and Budget Adherence</strong><br/>Controlling procurement costs and staying within budgets.`,
      line3: `<strong className='font-bold text-black-1000'>Compliance with Regulation</strong> <br/> Navigating complex regulations.`,
      line4: `<strong className='font-bold text-black-1000 inline-block'>Supply Chain Disruptions</strong> <br/> Handling unexpected supply chain disruptions.`,
      line5: `<strong className='font-bold text-black-1000'>Sustainability and Ethical Sourcing</strong> <br/> Meeting sustainability and ethical sourcing standards.`,
      line6: `<strong className='font-bold text-black-1000'>Data and Process Integration</strong> <br/> Integrating and processing data across platforms.`,
      line7: `<strong className='font-bold text-black-1000'>Supplier Diversity</strong> <br/> Ensuring a diverse supplier base.`,
      line8: `<strong className='font-bold text-black-1000'>Technological Adaptation and Innovation</strong> <br/> Adapting to new technologies and driving innovation.`,
    },
    // btnLabel: "Sign Up",
    // formLink: "https://forms.gle/G6H4VzgS97iQUmWz9",
    banner: "/assets/images/electronics_problem.png",
  },

  {
    id: 2,
    extraLabel: "Our Solutions",
    userType: "What Baz AI Can Do",
    description: {
      line1: `<strong className='font-bold text-black-1000 style='>Streamlined Supplier Management</strong> <br/> Advanced analytics to monitor supplier performance and predict risks.`,
      line2: `<strong className='font-bold text-black-1000'>Optimal Budget Allocation</strong> <br/> AI-driven tools to track expenses, forecast budgets, and identify cost-saving opportunities.`,
      line3: `<strong className='font-bold text-black-1000'>Compliance Intelligence</strong> <br/> Auto notifications on regulatory changes to ensure compliance.`,
      line4: `<strong className='font-bold text-black-1000'>Supply Chain Management</strong> <br/> AI agents to monitor and predict potential disruptions.`,
      line5: `<strong className='font-bold text-black-1000'>Assured Sustainability</strong> <br/> Traces supply origins to meet sustainability criteria.`,
      line6: `<strong className='font-bold text-black-1000'>Data and Process Efficient</strong> <br/> Seamless data integration and enhanced process coordination.`,
      line7: `<strong className='font-bold text-black-1000'>Supplier Analytics</strong> <br/> Engage with diverse suppliers to promote innovation.`,
      line8: `<strong className='font-bold text-black-1000'>Technological Innovation</strong> <br/> Automate procurement to foster innovation and efficiency.`,
    },
    // btnLabel: "Sign Up",
    // formLink: "https://forms.gle/G3fhPuDfPRhnxDre8",
    banner: "/assets/images/electronics_solution.jpeg",
  },

  {
    id: 3,
    extraLabel: "Call to Action",
    userType: "Grow Your Business",
    description: `Let us book an appointment to discuss how Baz AI and help you in your procurement`,
    btnLabel: "Book an Appointment",
    formLink:
      "https://outlook.office365.com/owa/calendar/AIAgentforBusiness@EkkBaz.com/bookings/",
    // banner: "/assets/images/electronics3.png",
    banner: "/assets/images/medicine3.png",
  },
];
