export const officeList = [
  {
    id: 1,
    usertype: "USA Office",
    description: {
      line1: `Block 71, 107 S B St #300`,
      line2: ` San Mateo`,
      line3: `California 94401`,
      line4: `United States`,
    },
    banner: "/assets/images/usa.jpeg",
  },
  {
    id: 2,
    usertype: "Singapore Office",
    description: {
      line1: `Paya Lebar Square #07-54`,
      line2: `60 Paya Lebar Road`,
      line3: `Singapore 409051`,
      line4: `Singapore`,
    },
    banner: "/assets/images/singapore.png",
  },
  {
    id: 3,
    usertype: "Bangladesh Office",
    description: {
      line1: `13th Floor, Razzak Plaza`,
      line2: ` Mog Bazar Moor`,
      line3: `Dhaka-1205`,
      line4: `Bangladesh`,
    },
    banner: "/assets/images/bangladesh.png",
  },
];
