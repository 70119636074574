import React from "react";
import parse from "html-react-parser";
import ButtonForBazAI from "./ButtonForBazAI";

export default function SingleUserFacility({
  id,
  userType,
  extraLabel,
  description,
  formLink,
  btnLabel,
  banner,
  from,
}) {
  return (
    <div
      className={`flex ${
        id % 2 !== 0 ? "lg:flex-row flex-col" : "lg:flex-row-reverse flex-col"
      } w-full lg:mb-16 mb-12 relative`}
    >
      <div className="lg:w-[50%] w-full lg:p-5 p-2 flex items-center">
        <img className={`w-full h-full object-cover`} src={banner} alt="" />
      </div>
      <div className="lg:w-[50%] w-full lg:p-5 p-2">
        {extraLabel && (
          <div
            className={`${
              from === "health"
                ? "text-[#418CFD] bg-[#418cfd1a]"
                : from === "electronics"
                ? "text-[#A662FF] bg-[#a662ff14]"
                : from === "food"
                ? "text-[#009061] bg-[#0090612f]"
                : from === "hotel"
                ? "text-[#EE6102] bg-[#ee600221]"
                : ""
            } font-bold lg:text-20 text-14  px-5 py-2 inline-block mb-3 mt-10 lg:mt-0 rounded-lg`}
          >
            {extraLabel}
          </div>
        )}
        <h6 className="font-bold text-[#000000] lg:text-[28px] text-20 lg:pb-3 pb-0 pt-3 lg:pt-1 leading-10">
          {userType}
        </h6>
        {typeof description === "object" ? (
          <>
            {/* <ul className='list-disc list-outside lg:px-5 px-2'>
							{Object.values(description).map((line, index) => (
								<li
									style={{ wordSpacing: "0px" }}
									key={index}
									className='leading-tight text-[#00000080] text-justify font-medium lg:text-[19px] text-14 mt-0.5'>
									{line && parse(line)}
								</li>
							))}
						</ul> */}

            <ul
              className="list-disc list-outside lg:px-5 px-2"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: " 3px",
                paddingLeft: "20px",
              }}
            >
              {Object.values(description).map((line, index) => (
                <li
                  style={{ textAlign: "left", whiteSpace: "normal" }}
                  key={index}
                  className="leading-tight text-[#00000080] text-justify font-medium lg:text-[19px] text-14 mt-0.5"
                >
                  {line && parse(line)}
                </li>
              ))}
            </ul>
          </>
        ) : (
          <p
            className={`font-medium lg:text-22 text-14  text-[#00000080]  text-left ${
              from === "food" ? "mt-5" : "mt-5"
            }`}
          >
            {description && parse(description)}
            {/* {description} */}
          </p>
        )}

        <div className="mt-8 lg:mt-0 lg:bottom-[20px] lg:right-0 lg:absolute lg:w-1/2 w-full lg:px-5">
          {btnLabel && formLink && (
            <ButtonForBazAI
              buttonType="anchor"
              link={formLink}
              label={btnLabel}
              from={from}
            />
          )}
        </div>
      </div>
    </div>
  );
}
