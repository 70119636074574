import React from "react";

export default function Partner() {
	return (
		<div className='container pt-10'>
			<h6 className='mb-10 font-bold text-[#000000] lg:text-[32px] text-20  lg:pb-5 pb-0 pt-5 lg:pt-0 text-center'>
				Partners
			</h6>
			<div className='flex lg:flex-row flex-col w-full lg:justify-around justify-center items-center'>
				<img
					className='lg:w-[230px] w-[150px] lg:h-[80px] h-[40px]'
					src='/assets/images/navidia.png'
					alt=''
				/>
				<img
					className='lg:w-[230px] w-[150px] lg:h-[80px] h-[40px] lg:mt-0 mt-5'
					src='/assets/images/partners/partner1.png'
					alt=''
				/>
			</div>
		</div>
	);
}