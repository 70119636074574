export const hotelFacilities = [
  {
    id: 1,
    extraLabel: "Your Challenges",
    userType: "Are you facing these challenges in your procurement?",
    description: {
      line1: `<strong className='font-bold text-black-1000'>Inconsistent Supplier Performance</strong> <br/> Unreliable suppliers not meeting quality or delivery standards.`,
      line2: `<strong className='font-bold text-black-1000'>High Procurement Costs</strong> <br/> Difficulty in negotiating better deals and reducing costs.`,
      line3: `<strong className='font-bold text-black-1000'>Manual Processes</strong> <br/> Too much time spent on repetitive and manual tasks.`,
      line4: `<strong className='font-bold text-black-1000 inline-block'>Lack of Visibility</strong> <br/> No real-time insights into procurement data.`,
      line5: `<strong className='font-bold text-black-1000'>Risk Management</strong> <br/> Struggling to identify and mitigate supply chain risks.`,
      line6: `<strong className='font-bold text-black-1000'>Compliance Issues</strong> <br/> Staying compliant with industry regulations.`,
      line7: `<strong className='font-bold text-black-1000'>Quality Control Problems</strong> <br/> Frequent issues with the quality of procured goods.`,
      line8: `<strong className='font-bold text-black-1000'>Sustainability Concerns</strong> <br/> Challenges in implementing sustainable practices.`,
    },
    // btnLabel: "Sign Up",
    // formLink: "https://forms.gle/G6H4VzgS97iQUmWz9",
    banner: "/assets/images/hotel_problem.jpg",
  },
  {
    id: 2,
    extraLabel: "Our Solutions",
    userType: "What Baz AI Can Do",
    description: {
      line1: `<strong className='font-bold text-black-1000 style='>Automate and Optimize</strong> <br/> Streamline processes and reduce manual work.`,
      line2: `<strong className='font-bold text-black-1000'>Enhance Supplier Relationships</strong> <br/> Improve collaboration and communication with suppliers.`,
      line3: `<strong className='font-bold text-black-1000'>Reduce Costs</strong> <br/> Find cost-saving opportunities with advanced analytics.`,
      line4: `<strong className='font-bold text-black-1000'>Improve Decision Making</strong> <br/> Use real-time data for strategic decisions.`,
      line5: `<strong className='font-bold text-black-1000'>Mitigate Risks</strong> <br/> Proactively manage supply chain risks.`,
      line6: `<strong className='font-bold text-black-1000'>Ensure Compliance</strong> <br/> Effortlessly adhere to industry standards.`,
      line7: `<strong className='font-bold text-black-1000'>Boost Quality</strong> <br/> Continuously monitor and improve quality.`,
      line8: `<strong className='font-bold text-black-1000'>Promote Sustainability</strong> <br/> Implement eco-friendly practices and reduce waste.`,
    },
    // btnLabel: "Sign Up",
    // formLink: "https://forms.gle/G3fhPuDfPRhnxDre8",
    banner: "/assets/images/hotel_solution.jpg",
  },
  {
    id: 3,
    extraLabel: "Call to Action",
    userType: "Set Up a Consultation",
    description: `Discover how our solution can enhance your business. Book an appointment for your Baz AI agent.`,
    btnLabel: "Book an Appointment",
    formLink:
      "https://outlook.office365.com/owa/calendar/AIAgentforBusiness@EkkBaz.com/bookings/",
    banner: "/assets/images/medicine3.png",
  },
];
