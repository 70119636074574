import React from "react";

export default function Cross() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 13 13'
			fill='none'>
			<path
				d='M7.64906 6.50023L12.7622 1.38765C13.0796 1.07026 13.0796 0.55566 12.7622 0.238291C12.4448 -0.0791047 11.9302 -0.0791047 11.6128 0.238291L6.50023 5.3514L1.38766 0.238291C1.07026 -0.0791047 0.555668 -0.0791047 0.238298 0.238291C-0.0790716 0.555686 -0.079097 1.07028 0.238298 1.38765L5.3514 6.50023L0.238298 11.6128C-0.079097 11.9302 -0.079097 12.4448 0.238298 12.7622C0.555693 13.0796 1.07029 13.0796 1.38766 12.7622L6.50023 7.64905L11.6128 12.7622C11.9302 13.0796 12.4448 13.0796 12.7622 12.7622C13.0796 12.4448 13.0796 11.9302 12.7622 11.6128L7.64906 6.50023Z'
				fill='black'
				fillOpacity='0.7'
			/>
		</svg>
	);
}
