export const customerBenefits = [
  {
    id: 1,
    tabTitle: "Comprehensive Customer Management",
    headline: "Comprehensive Customer Management",
    description:
      "Add and connect with a large customer base effortlessly. Manage customer lists, track payments, and monitor orders with ease. Identify and engage with both nearby and loyal customers to enhance relationships.",
    image: "/assets/images/customer-1.png",
  },
  {
    id: 2,
    tabTitle: "Streamlined Order Tracking",
    headline: "Streamlined Order Tracking",
    description:
      "Easily track customer orders and manage payment statuses, ensuring smooth transactions and prompt service. Maintain clear and organized records to support efficient operations.",
    image: "/assets/images/customer-2.png",
  },
  {
    id: 3,
    tabTitle: "AI-Enhanced Customer Insights",
    headline: "AI-Enhanced Customer Insights",
    description:
      "Leverage AI to analyze customer data, predict purchasing behavior, and personalize interactions. AI helps you understand customer preferences and optimize engagement strategies for improved retention and satisfaction",
    image: "/assets/images/salesforce-3.png",
  },
];
