import React from "react";
import SingleUserFacilityForFilm from "./SingleUserFacilityForFilm";

export default function UserFacilitesForFilm({ facilities, from }) {
  return (
    <div className="lg:pt-16 pt-12 container">
      {facilities.map((facility) => {
        return (
          <SingleUserFacilityForFilm
            key={facility?.id}
            id={facility?.id}
            userType={facility?.userType}
            description={facility?.description}
            formLink={facility?.formLink}
            btnLabel={facility?.btnLabel}
            banner={facility?.banner}
            from={from}
            demoButtonLabel={facility?.demoBtnLabel}
            demoLink={facility?.demoLink}
          />
        );
      })}
    </div>
  );
}
