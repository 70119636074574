import { createSlice } from "@reduxjs/toolkit";

const businessSettingsSlice = createSlice({
	name: "businessSettings",
	initialState: {
		data: {},
	},
	reducers: {
		setTradeLicense: (state, action) => {
			state.data = action.payload;
		},
		
		setProperty: (state, action) => {
			state.data = action.payload;
		},
	},
});

// Extract the action creators object and the reducer
export const { actions, reducer } = businessSettingsSlice;

export const setTradeLicense = (values) => async (dispatch, getState) => {
	let data = { ...getState().businessSettings.data, ...values };
	dispatch(actions.setTradeLicense(data));
};



export const setPropertyData = (values) => async (dispatch, getState) => {
	let data = { ...getState().businessSettings.data, ...values };
	dispatch(actions.setProperty(data));
};

// Export the reducer, either as a default or named export
export default reducer;
