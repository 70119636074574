const webUrl = document.location.origin;

export const DEVELOPMENT = {
  pass: "https://dpassapi.azurewebsites.net",
  business: "https://dbusiness.ekkbaz.com",
  product: "https://dproduct.ekkbaz.com",
  order: "https://dorderapi.azurewebsites.net",
  bucket: "https://dfileapi.azurewebsites.net",
  productAdmin: "https://dbiznpdtapi.azurewebsites.net",
  social: "https://dsocialapi.azurewebsites.net",
  webUrl: webUrl,
};

// todo: Uncomment the following line for development
// export var baseUrl = DEVELOPMENT;

export const PRODUCTION = {
  pass: "https://ppassapi.azurewebsites.net",
  business: "https://pbusiness.ekkbaz.com",
  product: "https://pproduct.ekkbaz.com",
  order: "https://porderapi.azurewebsites.net",
  bucket: "https://pfileapi.azurewebsites.net",
  productAdmin: "https://dbiznpdtapi.azurewebsites.net",
  social: "https://psocialapi.azurewebsites.net",
  webUrl: webUrl,
};

// todo: Uncomment the following line for production
export var baseUrl = PRODUCTION;
