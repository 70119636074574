import React from "react";
import SectionHeader from "../Home/SectionHeader";
import "./productsStyle.scss";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";

export default function Benefits({ benefits }) {
  return (
    <div className="container mt-5 lg:mt-5">
      <SectionHeader title="Key Benefits" />{" "}
      <Tabs>
        <div className="tabs-container">
          <TabList className="flex w-full m-0 p-0 lg:flex-row flex-col ">
            {benefits.map((benefit) => (
              <Tab key={benefit.id} className="tab">
                {benefit.tabTitle}
              </Tab>
            ))}
          </TabList>
          {benefits.map((benefit) => (
            <TabPanel key={benefit.id} className="tab-panel">
              <div className="grid lg:grid-cols-2 grid-cols-1 gap-10">
                {" "}
                <div className="flex flex-col justify-center h-full w-full">
                  <h2 className="font-bold lg:text-xl mb-3 text-lg">{benefit.headline}</h2>
                  <p className="text-lg">{benefit.description}</p>
                </div>
                <div>
                  {benefit.image && (
                    <img
                      src={benefit.image}
                      alt={benefit.headline}
                      className="lg:h-[300px] w-full"
                    />
                  )}
                </div>
              </div>
            </TabPanel>
          ))}
        </div>
      </Tabs>
    </div>
  );
}
