export const inventoryBenefits = [
  {
    id: 1,
    tabTitle: "Real-Time Inventory and Price Management",
    headline: "Real-Time Inventory and Price Management",
    description:
      "Stay on top of your inventory and pricing with real-time updates as supplies are received from suppliers. Our system ensures accurate stock levels and pricing information, reducing the risk of overstocking, stockouts, and pricing errors.",
    image: "/assets/images/inventory-1.png",
  },
  {
    id: 2,
    tabTitle: "Efficient Product and Offer Creation",
    headline: "Efficient Product and Offer Creation",
    description:
      "Easily create and manage products, packs, and offers with our intuitive platform. Streamline your inventory management by quickly updating and organizing your product catalog. Simplify the process of launching new offers to keep your customers engaged and drive sales growth.",
    image: "/assets/images/inventory-2.png",
  },
  {
    id: 3,
    tabTitle: "AI-Driven Features for Inventory",
    headline: "AI-Driven Features for Inventory ",
    description:
      "Enhance your inventory management with AI-driven features that optimize restocking, forecast demand trends, and provide real-time tracking. These advanced capabilities enable strategic decisions, boosting efficiency and business performance.",
    image: "/assets/images/salesforce-3.png",
  },
];
