export const wareHouseBenefits = [
    {
      id: 1,
      tabTitle: "Efficient Warehouse Operations",
      headline: "Efficient Warehouse Operations",
      description:
        "Manage product stock with ease, keeping inventory updated in real time. Automatically publish and unpublish products based on stock levels to ensure accurate availability information and streamline inventory control.",
      image: "/assets/images/inventory-1.png",
    },
    {
      id: 2,
      tabTitle: "AI-Driven Intelligences",
      headline: "AI-Driven Intelligences",
      description:
        "Utilize AI to forecast demand and automate stock replenishment, minimizing shortages and overstock situations. AI insights help optimize inventory levels, enhancing overall warehouse efficiency and reducing operational costs.",
      image: "/assets/images/salesforce-3.png",
    },
    // {
    //   id: 3,
    //   tabTitle: "AI-Driven Features for Optimal Sales",
    //   headline: "AI-Driven Features for Optimal Sales",
    //   description:
    //     "Utilize AI-powered voice and image ordering systems to streamline the order placement process. Benefit from price analysis across different suppliers, allowing you to identify and secure the best deals.",
    //   image: "/assets/images/money.jpeg",
    // },
  ];
  