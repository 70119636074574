import React from "react";

export default function FooterV2() {
	return (
		<div className='container pt-[150px]'>
			{/* <div className="flex justify-center items-center pt-[100px]">
        <div className="flex">
          <a
            href="mailto:Sales@ekkbaz.ai"
            className="text-delivered mr-5 cursor-pointer font-semibold hover:underline"
          >
            Sales
          </a>
          <span className="text-808080">|</span>
          <a
            href="mailto:Help@ekkbaz.ai"
            className="text-delivered mx-5 cursor-pointer font-semibold hover:underline"
          >
            Help
          </a>
          <span className="text-808080">|</span>
          <a
            href="https://www.facebook.com/EkkBazBangladesh"
            target="_blank"
            rel="noreferrer"
            className="ml-5 text-delivered font-semibold hover:underline"
          >
            Message Us
          </a>
        </div>
      </div> */}
			{/* <div className="flex justify-center items-center w-full mt-[30px] mb-4">
        <div className="flex items-center ">
          <a
            href="https://www.facebook.com/EkkBazBangladesh"
            target="_blank"
            className="zoom-image transform transition-transform hover:scale-110 mr-4"
            rel="noreferrer"
          >
            <img
              className="w-[30px] h-[30px]"
              src="/assets/images/facebook_new.png"
              alt=""
            />
          </a>
          <a
            href="https://twitter.com/EkkBaz"
            target="_blank"
            className="zoom-image transform transition-transform hover:scale-110 mr-4"
            rel="noreferrer"
          >
            <img
              className="w-[30px] h-[30px]"
              src="/assets/images/twitter_new.png"
              alt=""
            />
          </a>
          <a
            href="https://www.linkedin.com/company/7603237/admin/"
            target="_blank"
            className="zoom-image transform transition-transform hover:scale-110 mr-4"
            rel="noreferrer"
          >
            <img
              className="w-[30px] h-[30px]"
              src="/assets/images/linkedin_new.png"
              alt=""
            />
          </a>
          <a
            href="https://www.instagram.com/ekkbaz/"
            target="_blank"
            className="zoom-image transform transition-transform hover:scale-110 mr-4"
            rel="noreferrer"
          >
            <img
              className="w-[30px] h-[30px]"
              src="/assets/images/instagram_new.png"
              alt=""
            />
          </a>
          <a
            href="https://www.youtube.com/c/EkkBaz"
            target="_blank"
            className="zoom-image transform transition-transform hover:scale-110"
            rel="noreferrer"
          >
            <img
              className="w-[30px] h-[30px]"
              src="/assets/images/youtube_new.png"
              alt=""
            />
          </a>
        </div>
      </div> */}
			<hr className='border-delivered border-2 border-top rounded-[100%] w-[85%] m-auto' />
			<div className='flex flex-col md:flex-row md:justify-between justify-center items-center py-3'>
				<img
					className='w-[100px]'
					src='/assets/images/LogoAI.png'
					alt='logo'
				/>
				<p className='text-16 font-normal text-808080 mt-5 lg:mt-0'>
					&#169; <b>EkkBaz.AI Pte. Ltd.</b> All Rights Reserved.
				</p>
			</div>
		</div>
	);
}
