export const supplierBenefits = [
  {
    id: 1,
    tabTitle: "Sync with Your Suppliers",
    headline: "Sync with Your Suppliers",
    description:
      "Effortlessly connect with suppliers to expand your network and enhance collaboration. Add them to your business list and invite them for opportunities, ensuring strong relationships and seamless communication.",
    image: "/assets/images/supplier-list.jpg",
  },
  {
    id: 2,
    tabTitle: "Real-time Order Management",
    headline: "Real-time Order Management",
    description:
      "Manage orders instantly, track status in real-time, and ensure timely deliveries. Streamline your operations with up-to-the-minute updates and seamless coordination.",
    image: "/assets/images/distributor-orderlist.jpg",
  },
  {
    id: 3,
    tabTitle: "AI-Driven Features",
    headline: "AI-Driven Features",
    description:
      "Leverage AI to automate and optimize tasks, from smart order processing to predictive analytics. Enhance decision-making, reduce errors, and improve efficiency with intelligent insights and recommendations.",
    image: "/assets/images/salesforce-3.png",
  },
];
