import { createSlice, createSelector } from "@reduxjs/toolkit";
import { updateToken } from "../../config/SuperFetch";
import { setLocalUser } from "config/user";
import Auth from "services/Auth";
// import {updateToken} from '../../api/SuperFetch';
// import {clearAuth} from '../../api/auth'
// import {getUser, setUser} from 'utils/user';
// import {setRole} from 'utils/user';

const authSlice = createSlice({
	name: "auth",
	initialState: {
		loading: false,
		loginStatus: false,
		data: {},
		registerData: [],
		error: "",
		success: "",
	},
	reducers: {
		login: (state, action) => {
			state.loading = true;
			state.error = "";
			state.success = "";
		},
		loginSuccess: (state, action) => {
			state.loading = false;
			state.error = "";
			state.loginStatus = true;
			state.data = action.payload;
			state.success = "Login Successful! Welcome home! :D";
		},
		loginFailed: (state, action) => {
			state.loading = false;
			state.error = action.payload;
			state.success = "";
		},
		forwordTodashboard: (state, action) => {
			state.loginStatus = true;
		},

		registration: (state, action) => {
			state.loading = true;
			state.error = "";
			state.success = "";
		},
		registrationSuccess: (state, action) => {
			state.loading = false;
			state.error = "";
			state.registerData = action.payload;
			state.success = "Registration Successful!";
		},
		registrationFailed: (state, action) => {
			state.loading = false;
			state.error = action.payload;
			state.success = "";
		},

		logout: (state, action) => {
			state.loginStatus = false;
			state.data = {};
			state.accounts = [];
			state.error = "";
			state.success = "";
		},
	},
});

// Extract the action creators object and the reducer
export const { actions, reducer } = authSlice;

export const selectors = {
	getAuth: createSelector(
		(state) => state.auth,
		(data) => data
	),
	getUser: createSelector(
		(state) => state.auth,
		(data) => data.userInfo
	),
};

export const login = (values) => async (dispatch, getState) => {
	dispatch(actions.login());
	try {
		const response = await Auth.login(values);
		if (response?.status === 200) {
			updateToken(response);
			setLocalUser(response?.data);

			dispatch(
				actions.loginSuccess({
					...response,
				})
			);
			// history("/");
		} else {
			// history("/");
		}
	} catch (error) {
		dispatch(actions.loginFailed(error.errorData.message));
		return error.errorData;
	}
};
export const loginFromSignUp = (res) => async (dispatch, getState) => {
	dispatch(actions.loginSuccess(res));
};
export const logout = () => async (dispatch, getState) => {
	dispatch(actions.logout());
	localStorage.removeItem("master_token");
	localStorage.removeItem("_u");
	localStorage.removeItem("_b");
	localStorage.removeItem("cb");
	localStorage.setItem("auth", "false");
};
export const fowordToDashboard = (values) => async (dispatch) => {
	dispatch(actions.forwordTodashboard());
};

// Export the reducer, either as a default or named export
export default reducer;
