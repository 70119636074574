export const inventoryData = [
	{
		id: 1,
		tabTitle: "Seamless Delivery Management",
		userType: "Real-Time Mastery of Inventory and Pricing",
		description:
			"Stay in command with real-time updates as supplies flow in from suppliers. Our system ensures precise stock levels and accurate pricing, eliminating the risks of overstocking, stockouts, and pricing errors.",
		banner: "/assets/images/products/inventory1.png",
	},
	{
		id: 2,
		tabTitle: "Real Time Order Management",
		userType: "Effortless Product and Offer Creation",
		description:
			"Craft and manage products, packs, and offers with unparalleled ease. Our intuitive platform simplifies inventory management, allowing you to update and organize your product catalog effortlessly. Launch new offers seamlessly to engage customers and drive sales",
		banner: "/assets/images/products/inventory2.png",
	},
	{
		id: 3,
		tabTitle: "AI-Driven Delivery Optimization",
		userType: "AI-Enhanced Inventory Management",
		description:
			"Empower your inventory strategy with AI-driven features that optimize restocking, predict demand trends, and provide real-time tracking. Make strategic decisions that elevate efficiency and boost business performance.",
		banner: "/assets/images/products/inventory3.png",
	},
];
