export const arBenefits = [
  {
    id: 1,
    tabTitle: "Efficient Invoice Management",
    headline: "Efficient Invoice Management",
    description:
      "Automate your invoice generation and distribution processes, ensuring timely and accurate billing. Keep your cash flow steady with streamlined payment tracking and management.",
    image: "/assets/images/invoice-pdf.jpg",
  },
  {
    id: 2,
    tabTitle: "Automated Payment Reminders",
    headline: "Automated Payment Reminders",
    description:
      "Never miss a payment deadline with automated reminders that keep your customers informed. Enhance your credit management by ensuring timely payments and reducing the risk of overdue accounts.",
    image: "/assets/images/create-order-payment.jpg",
  },
  {
    id: 3,
    tabTitle: "AI-Driven Payment Insights",
    headline: "AI-Driven Payment Insights",
    description:
      "Use AI to monitor payment patterns and optimize credit management. Predict payment behaviour's for better cash flow and financial stability.",
    image: "/assets/images/salesforce-3.png",
  },
];
