import { useSelector } from "react-redux";
import SwiperCore, { Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import SectionHeader from "./SectionHeader";
import "./style.scss";

let slideData = [
	// {
	// 	image: "assets/images/navidia.png",
	// 	name: "nvidia",
	// },
	{
		image: "/assets/images/esing-logo.png",
		name: "easin-logo",
	},
	{
		image: "/assets/images/partners/partner1.png",
		name: "Microsoft",
	},
	{
		image: "/assets/images/partners/partner2.png",
		name: "aws",
	},
	{
		image: "/assets/images/partners/partner3.png",
		name: "mongodb",
	},
	{
		image: "/assets/images/partners/partner4.png",
		name: "aws",
	},
	{
		image: "/assets/images/partners/partner5.png",
		name: "aws",
	},

	{
		image: "/assets/images/partners/partner6.png",
		name: "aws",
	},
	{
		image: "/assets/images/partners/partner7.png",
		name: "aws",
	},
	{
		image: "/assets/images/partners/partner8.png",
		name: "aws",
	},
	{
		image: "/assets/images/partners/partner9.png",
		name: "aws",
	},
];
export default function Partners() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	SwiperCore.use([Autoplay]);
	return (
		<div className='container m-auto pb-[40px] md:pb-[70px] lg:pb-[70px] bg-F2F3F7'>
			<SectionHeader title={ekkLanguage.landing.partners} />
			<div>
				<Swiper
					modules={[Pagination]}
					pagination={{ clickable: true }}
					autoplay={{ delay: 2500, disableOnInteraction: false }}
					breakpoints={{
						280: {
							slidesPerView: 2,
							spaceBetween: 20,
						},
						320: {
							slidesPerView: 3,
							spaceBetween: 20,
						},
						599: {
							slidesPerView: 4,
							spaceBetween: 25,
						},
						768: {
							slidesPerView: 5,
							spaceBetween: 40,
						},
					}}>
					{slideData.map((el, i) => {
						return (
							<SwiperSlide key={i}>
								<div className='pb-10'>
									<img
										src={el.image}
										alt='partners'
										className='m-auto'
									/>
								</div>
							</SwiperSlide>
						);
					})}
				</Swiper>
			</div>
		</div>
	);
}
