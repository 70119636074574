import React from "react";
import BannerContent from "./BannerContent";
import "./style.scss";

export default function BannerArea() {
	return (
		<div className='relative banner-area-wrapper md:mb-[120px]'>
			<div className='pb-[70px] sm:pb-[70px] container m-auto  bg-F2F3F7'>
				<div className='flex md:space-x-0 lg:space-x-10 relative z-10'>
					{/* <div className='w-5/12 hidden lg:block'>
						<BannerSlider />
					</div> */}
					<div className=' w-full banner-content pb-[70px] lg:pb-[0]'>
						<BannerContent />
					</div>
				</div>
			</div>
		</div>
	);
}
