import React from "react";

export default function ButtonForBazAI({
  buttonType,
  link,
  label,
  from,
  demoButtonLabel,
  demoLink,
  showDemoButton,
}) {
  return (
    <div className="relative">
      {buttonType === "anchor" && (
        <div className="flex">
          <a
            href={link}
            target="_blank"
            rel="noreferrer"
            className={` text-white font-bold text-14 py-4 block text-center border-2  hover:bg-white ${
              from === "film"
                ? showDemoButton
                  ? "bg-black-1000 border-black-1000 hover:text-black-1000 hover:border-black-1000 w-[50%] mr-3"
                  : "bg-black-1000 border-black-1000 hover:text-black-1000 hover:border-black-1000 w-[100%]"
                : from === "health"
                ? "bg-[#418CFD] border-[#418CFD] hover:text-[#418CFD] hover:border-[#418CFD] w-[100%]"
                : from === "electronics"
                ? "bg-[#A662FF] border-[#A662FF] hover:text-[#A662FF] hover:border-[#A662FF] w-[100%]"
                : from === "food"
                ? "bg-[#009061] border-[#009061] hover:text-[#009061] hover:border-[#009061] w-[100%]"
                : from === "hotel"
                ? "bg-[#EE6102] border-[#EE6102] hover:text-[#EE6102] hover:border-[#EE6102] w-[100%]"
                : ""
            }`}
          >
            {label}
          </a>
          {from === "film" && showDemoButton && (
            <a
              href={demoLink}
              target="_blank"
              rel="noreferrer"
              className={` text-white font-bold text-14 py-4 block text-center border-2  hover:bg-white ${
                from === "film"
                  ? "bg-black-1000 border-black-1000 hover:text-black-1000 hover:border-black-1000 w-[50%]"
                  : from === "health"
                  ? "bg-[#418CFD] border-[#418CFD] hover:text-[#418CFD] hover:border-[#418CFD] w-[100%]"
                  : from === "electronics"
                  ? "bg-[#A662FF] border-[#A662FF] hover:text-[#A662FF] hover:border-[#A662FF] w-[100%]"
                  : from === "food"
                  ? "bg-[#009061] border-[#009061] hover:text-[#009061] hover:border-[#009061] w-[100%]"
                  : ""
              }`}
            >
              {demoButtonLabel}
            </a>
          )}
        </div>
      )}
      {from === "film" && (
        <div
          className={`font-bold text-14 py-[22px] block text-center absolute top-3  border-2 ${
            from === "film"
              ? showDemoButton
                ? "border-black-1000 w-[50%] left-0"
                : "border-black-1000 w-[100%] left-1.5"
              : from === "health"
              ? "border-[#418CFD] w-[100%]"
              : from === "electronics"
              ? "border-[#A662FF] w-[100%]"
              : from === "food"
              ? "border-[#009061] w-[100%]"
              : ""
          } z-[-50]`}
        ></div>
      )}
      {from === "film" && showDemoButton && (
        <div
          className={`font-bold text-14 py-[22px] block text-center absolute top-3 right-[-6px] border-2 ${
            from === "film"
              ? "border-black-1000 w-[50%]"
              : from === "health"
              ? "border-[#418CFD] w-[100%]"
              : from === "electronics"
              ? "border-[#A662FF] w-[100%]"
              : from === "food"
              ? "border-[#009061] w-[100%]"
              : ""
          } z-[-50]`}
        ></div>
      )}
    </div>
  );
}
