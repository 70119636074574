export const distributorData = [
	{
		id: 1,
		tabTitle: "Seamless Delivery Management",
		userType: "Revolutionize Your Distribution Process",
		description:
			"Elevate your distribution with cutting-edge tools designed for maximum efficiency. Automate product listings, assign roles with precision, and track orders in real-time to ensure flawless execution. Streamlined processes not only enhance operational efficiency but also attract more orders from your distributors.",
		banner: "/assets/images/products/distributor1.png",
	},
	{
		id: 2,
		tabTitle: "Real Time Order Management",
		userType: "Connect with a Global Network of Distributors",
		description:
			"Expand your influence by seamlessly connecting with a vast network of distributors. Our platform fosters effortless communication and collaboration, ensuring your products reach their target markets. Strengthen relationships and drive order growth with our extensive distribution network.",
		banner: "/assets/images/products/distributor2.png",
	},
	{
		id: 3,
		tabTitle: "AI-Driven Delivery Optimization",
		userType: "Unleash AI-Driven Excellence",
		description:
			"Harness the power of AI to supercharge your operations and make informed decisions. Our intelligent features provide deep insights for optimizing supply chains and strategic planning, keeping your business at the forefront of innovation and growth.",
		banner: "/assets/images/products/distributor3.png",
	},
];
