import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

export default function CustomNavigateSelect({ options, title, width }) {
  const navigate = useNavigate();
  const [showOptions, setShowOptions] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOptionClick = (path) => {
    navigate(path);
    setShowOptions(false);
  };

  return (
    <div className="relative w-[120px]" ref={dropdownRef}>
      <div
        className="cursor-pointer p-[10px] text-22"
        onClick={() => setShowOptions(!showOptions)}
      >
        {title}
      </div>
      {showOptions && (
        <div
          className={`absolute top-[100%]  bg-[#fff] z-[1000] ${
            width ? "lg:left-0 left-[-100px]" : "left-0"
          }`}
          style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)" }}
        >
          {options.map((option) => (
            <div
              key={option.path}
              className={`py-[8px] px-[20px] cursor-pointer hover:bg-[#f0f0f0] ${
                width ? `lg:w-[${width}] w-[230px]` : "w-[165px]"
              }`}
              style={{ transition: "background-color 1s ease" }}
              onClick={() => handleOptionClick(option.path)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
