import React, { useRef } from "react";

export default function BazAiScreenShot() {
	const imageRef = useRef(null);

	const handleMouseMove = (event) => {
		const rect = imageRef.current.getBoundingClientRect();
		const x = event.clientX - rect.left; // Mouse position relative to the image
		const y = event.clientY - rect.top; // Mouse position relative to the image
		const centerX = rect.width / 2;
		const centerY = rect.height / 2;

		// Calculate the amount of rotation based on mouse position
		const rotateX = ((y - centerY) / centerY) * -2; // Rotate based on Y axis
		const rotateY = ((x - centerX) / centerX) * 2; // Rotate based on X axis

		// Apply the transformation
		imageRef.current.style.transform = `rotateX(${rotateX}deg) rotateY(${rotateY}deg) scale(1.01)`;
		imageRef.current.style.transition = "transform 0.1s ease-out"; // Smooth transition for hover
	};

	const handleMouseLeave = () => {
		// Reset the transformation when the mouse leaves the image
		imageRef.current.style.transform = "rotateX(0) rotateY(0) scale(1)";
		imageRef.current.style.transition = "transform 0.5s ease"; // Smooth return to normal
	};
	return (
		<div className='container m-auto bg-F2F3F7 customer-impact '>
			{/* <div className='image-container'>
				<img
					style={{
						boxShadow:
							"0 4px 6px rgba(0,0,0,.1),0 10px 15px rgba(0,0,0,.1)",
					}}
					src='/assets/images/bazai.png'
					alt='img'
					className='rounded-[20px]'
				/>
			</div> */}
			<div className='image-container'>
				<img
					style={{
						boxShadow:
							"0 4px 6px rgba(0,0,0,.1),0 10px 15px rgba(0,0,0,.1)",
					}}
					ref={imageRef}
					src='/assets/images/bazai.png'
					alt='3D effect'
					onMouseMove={handleMouseMove}
					onMouseLeave={handleMouseLeave}
					className='image-3d rounded-[20px]'
				/>
			</div>
		</div>
	);
}
