export const customerData = [
	{
		id: 1,
		extraLabel: "Your Challenges",
		userType: "Comprehensive Customer Connectivity",
		description:
			"Effortlessly manage a vast customer base. Track payments, monitor orders, and engage with both local and loyal customers to build stronger relationships.",
		banner: "/assets/images/products/customer1.png",
	},

	{
		id: 2,
		extraLabel: "Our Solutions",
		userType: "Seamless Order Tracking",
		description:
			"Keep tabs on customer orders and payment statuses with ease, ensuring smooth transactions and prompt service. Maintain clear, organized records to support efficient operations.",
		banner: "/assets/images/products/customer2.png",
	},

	{
		id: 3,
		extraLabel: "Call to Action",
		userType: "AI-Driven Customer Insights",
		description: `Leverage AI to decode customer behavior, predict purchasing patterns, and tailor interactions. Understand preferences and refine engagement strategies to boost retention and satisfaction.`,
		banner: "/assets/images/products/customer3.png",
	},
];
