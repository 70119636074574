export const arData = [
	{
		id: 1,
		extraLabel: "Your Challenges",
		userType: "Streamlined Invoice Mastery",
		description:
			"Automate your invoicing process for timely and accurate billing. Keep your cash flow steady with efficient payment tracking and management.",
		banner: "/assets/images/products/ar-auto1.png",
	},

	{
		id: 2,
		extraLabel: "Our Solutions",
		userType: "Effortless Payment Reconciliation",
		description:
			"Automate payment reconciliation to match payments with invoices swiftly, minimizing manual effort and reducing errors. Maintain accurate financial records with real-time updates.",
		banner: "/assets/images/products/ar-auto2.png",
	},

	{
		id: 3,
		extraLabel: "Call to Action",
		userType: "AI-Driven Payment Insights",
		description: `Utilize AI to monitor payment patterns and optimize credit management. Predict payment behaviors to maintain healthy cash flow and financial stability.`,
		banner: "/assets/images/products/ar-auto3.png",
	},
];
