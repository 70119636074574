import React, { useEffect, useState } from "react";
import BazAINavigation from "../common/BazAINavigation";
import Banner from "../common/Banner";
import SocialMedia from "./SocialMedia";
import ContactEmails from "./ContactEmails";
import Locations from "./Locations";
import FooterV2 from "../common/FooterV2";
import BazAIHelmet from "../common/BazAIHelmet";
import { baseUrl } from "config/apiUrl";

export default function ContactUsPage() {
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  useEffect(() => {
    function onScroll() {
      let currentPosition = document.documentElement.scrollTop;
      if (currentPosition > 150) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
      setScrollTop(currentPosition);
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);
  return (
    <>
      <BazAIHelmet
        title="EkkBaz AI"
        description="Transforming businesses with AI Agents."
        image="/assets/images/LogoAI.png"
        url={`${baseUrl.webUrl}/company/contact-us`}
      />
      <BazAINavigation
        scrolling={scrolling ? "isScrolling" : ""}
        from="mainLanding"
      />
      <div className="pb-12 container">
        <Banner
          headline="Contact Us"
          bannerImage="/assets/images/contactUs.jpeg"
          from="contactUs"
          alreadyHaveTopPadding={true}
        />
      </div>
      <SocialMedia />
      <ContactEmails />
      <Locations />
      <FooterV2 />
    </>
  );
}
