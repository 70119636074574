export const foodFacilites = [
  {
    id: 1,
    extraLabel: "Your Challenges",
    userType:
      "Are these challenges affecting your procurement processes, wasting up to 2.5 hours daily?",
    description: {
      line1: `Difficulty in managing and coordinating with multiple suppliers.`,
      line2: `Long lead times and errors in order processing.`,
      line3: `Increased costs due to manual processes and inefficiencies.`,
      line4: `Limited visibility into inventory levels and supply chain activities.`,
      line5: `Delays in making informed procurement decisions due to a lack of real-time data.`,
    },
    // btnLabel: "Sign Up",
    // formLink: "https://forms.gle/G6H4VzgS97iQUmWz9",
    banner: "/assets/images/food_problem.jpeg",
  },

  {
    id: 2,
    extraLabel: "Our Solutions",
    userType: "BAZ AI's Tailored Solutions for Consumer Goods",
    description: {
      line1: `<strong className='font-bold text-black-1000 style='>Automated Demand Forecasting</strong> <br/> Predict demand for seasonal and perishable items to ensure optimal inventory levels.`,
      line2: `<strong className='font-bold text-black-1000'>Supplier Performance Analysis</strong> <br/> Assess supplier reliability with data-driven insights for better procurement decisions.`,
      line3: `<strong className='font-bold text-black-1000'>Lifecycle Management</strong> <br/> Optimize procurement from product launch to end-of-life.`,
      line4: `<strong className='font-bold text-black-1000'>Order Automation</strong> <br/> Speed up order processing and reduce errors.`,
      line5: `<strong className='font-bold text-black-1000'>Dynamic Pricing</strong> <br/> Adjust prices based on market trends and demand to maximize profitability.`,
      line6: `<strong className='font-bold text-black-1000'>Inventory Optimization</strong> <br/> Manage stock to avoid shortages and excess.`,
      line7: `<strong className='font-bold text-black-1000'>Cost Savings</strong> <br/> Save up to 25% in costs through strategic sourcing and spend analysis.`,
      line8: `<strong className='font-bold text-black-1000'>Real-Time Insights</strong> <br/> Gain visibility and make quick, informed decisions with real-time data.`,
    },
    // btnLabel: "Sign Up",
    // formLink: "https://forms.gle/G3fhPuDfPRhnxDre8",
    banner: "/assets/images/food_solution.jpeg",
  },

  {
    id: 3,
    extraLabel: "Call to Action",
    userType: "Set Up a Consultation",
    description: `Discover how AI Agent can boost your business. Book an appointment for a tailored solution.`,
    btnLabel: "Book an Appointment",
    formLink:
      "https://outlook.office365.com/owa/calendar/AIAgentforBusiness@EkkBaz.com/bookings/",
    banner: "/assets/images/medicine3.png",
  },
];
