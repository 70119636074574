export const warehouseData = [
	{
		id: 1,
		tabTitle: "Seamless Delivery Management",
		userType: "Streamlined Warehouse Operations",
		description:
			"Effortlessly manage inventory, keeping stock levels accurate and up-to-date. Automatically publish and unpublish products based on availability, ensuring precision in inventory control.",
		banner: "/assets/images/products/warehouse1.png",
	},
	{
		id: 2,
		tabTitle: "Real Time Order Management",
		userType: "AI-Driven Warehouse Intelligence",
		description:
			"Utilize AI to forecast demand and automate stock replenishment, preventing shortages and overstock. Optimize inventory levels, enhancing warehouse efficiency and cutting operational costs.",
		banner: "/assets/images/products/warehouse2.png",
	},
];
