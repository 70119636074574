import React from "react";
import SectionHeader from "../Home/SectionHeader";
import { officeList } from "data/offices";

export default function Locations() {
  return (
    <>
      <SectionHeader title="Our Location" />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 container mt-5">
        {officeList.map((office) => {
          return (
            <div key={office?.id} className="bg-white p-5 rounded-lg">
              <h6 className="font-bold text-[#000000] lg:text-[20px] text-20  lg:pb-5 pb-0 pt-5 lg:pt-2">
                {office?.usertype}
              </h6>
              <p className="font-medium lg:text-16 text-14 text-justify text-[#00000080]">
                {office?.description?.line1}
              </p>
              <p className="font-medium lg:text-16 text-14 text-justify text-[#00000080] ">
                {office?.description?.line2}
              </p>{" "}
              <p className="font-medium lg:text-16 text-14 text-justify text-[#00000080]">
                {office?.description?.line3}
              </p>
              {office?.description?.line4 && (
                <p className="font-medium lg:text-16 text-14 text-justify text-[#00000080]">
                  {office?.description?.line4}
                </p>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
}
