export const supplierData = [
	{
		id: 1,
		tabTitle: "Seamless Delivery Management",
		userType: "Effortless Supplier Sync",
		description:
			"Easily connect with suppliers to grow your network and enhance collaboration. Add them to your business list and invite them for new opportunities, ensuring robust partnerships and seamless communication.",
		banner: "/assets/images/products/supplier1.png",
	},
	{
		id: 2,
		tabTitle: "Real Time Order Management",
		userType: "Instant Order Management",
		description:
			"Manage orders in real-time, tracking status and ensuring timely deliveries. Streamline operations with up-to-the-minute updates and flawless coordination.",
		banner: "/assets/images/products/supplier2.png",
	},
	{
		id: 3,
		tabTitle: "AI-Driven Delivery Optimization",
		userType: "AI-Powered Efficiency",
		description:
			"Leverage AI to automate and optimize tasks, from intelligent order processing to predictive analytics. Enhance decision-making, reduce errors, and boost operational efficiency.",
		banner: "/assets/images/products/supplier3.png",
	},
];
