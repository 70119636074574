import ButtonForBazAI from "../common/ButtonForBazAI";

export default function SingleUserFacilityForFilm({
  id,
  userType,
  description,
  formLink,
  btnLabel,
  banner,
  from,
  demoButtonLabel,
  demoLink,
}) {
  return (
    <div
      className={`flex ${
        id % 2 !== 0 ? "lg:flex-row flex-col" : "lg:flex-row-reverse flex-col"
      } w-full lg:mb-16 mb-12`}
    >
      <div className="lg:w-[50%] w-full lg:p-5 p-2">
        <img className="w-full h-full" src={banner} alt="" />
      </div>
      <div className="lg:w-[50%] w-full lg:p-5 p-2">
        <h6 className="font-bold text-[#000000] lg:text-[30px] text-20  lg:pb-5 pb-0 pt-5 lg:pt-2">
          {userType}
        </h6>
        <div className="font-medium lg:text-20 text-14 text-justify text-[#00000080] mt-5">
          {description?.line1}
          <br />
          <h6 className="mt-3">{description?.line2}</h6>
        </div>
        <div className="mt-8">
          <ButtonForBazAI
            buttonType="anchor"
            link={formLink}
            label={btnLabel}
            from={from}
            demoLink={demoLink}
            demoButtonLabel={demoButtonLabel}
            showDemoButton={demoButtonLabel ? true : false}
          />
        </div>
      </div>
    </div>
  );
}
