import React from "react";
import SingleUserFacility from "./SingleUserFacility";

export default function UserFacilites({ facilities, from }) {
  return (
    <div className="lg:pt-16 pt-12 container">
      {facilities.map((facility) => {
        return (
          <SingleUserFacility
            key={facility?.id}
            id={facility?.id}
            userType={facility?.userType}
            extraLabel={facility?.extraLabel}
            description={facility?.description}
            formLink={facility?.formLink}
            btnLabel={facility?.btnLabel}
            banner={facility?.banner}
            from={from}
          />
        );
      })}
    </div>
  );
}
