import React from "react";
import SectionHeader from "../Home/SectionHeader";

export default function OurTeam({ teams }) {
  return (
    <>
      <div className="mt-16 mb-3">
        <SectionHeader title="Our Team" />
      </div>
      <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 container">
        {teams.map((el, i) => {
          return (
            <div className="mb-10 text-center" key={i}>
              <img
                src={el?.image}
                alt="avatar"
                className="rounded-full w-[250px] h-[250px] mb-[15px] m-auto"
              />
              <h5
                className="text-26 font-semibold mb-[15px]"
                style={{ color: "#222" }}
              >
                {el?.name}
              </h5>
              <img src={el?.logo} alt="logo" className="mb-5 m-auto" />
              <div className="flex justify-center align-center">
                {el?.linkDin && (
                  <a
                    href={el?.linkDin}
                    className="mx-[10px]"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="m-auto w-5"
                      src="/assets/images/linkdin.png"
                      alt="icon"
                    />
                  </a>
                )}
                {el?.x && (
                  <a
                    href={el?.x}
                    className="mx-[10px]"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="m-auto w-5"
                      src="/assets/images/x.png"
                      alt="icon"
                    />
                  </a>
                )}
                {el?.fb && (
                  <a
                    href={el?.fb}
                    className="mx-[10px]"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="m-auto w-5"
                      src="/assets/images/fb.png"
                      alt="icon"
                    />
                  </a>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
