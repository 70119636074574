import moment from "moment";

import React from "react";

import { Link, useNavigate } from "react-router-dom";

export default function SingleBlog({ item }) {
	const navigate = useNavigate();

	let span = document.createElement("span");

	span.innerHTML = item.content;

	let content = (span.textContent || span.innerText).substring(0, 100) + "...";

	return (
		<div
			onClick={() => navigate(`/post/en/${item?.id}`)}
			className='bg-white overflow-hidden rounded-[10px] cursor-pointer'
			style={{ boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.05)" }}>
			<div className='h-[250px] overflow-hidden'>
				<img
					src={item.thumbnail_image}
					alt='blogImage'
					className='object-cover w-full h-full'
				/>
			</div>

			<div className='pt-4 px-5 pb-4'>
				<h5 className='text-14 sm:text-18 lg:text-24 font-bold text-262626-1000 pb-[10px] h-[45px] md:h-[76px]'>
					{item.title.length > 30
						? item.title.substring(0, 35) + "..."
						: item.title}
				</h5>

				<p className='text-8 sm:text-16 font-normal text-808080 pb-[14px]'>
					{content}
				</p>

				<div className='flex justify-between'>
					<p className='text-12 sm:text-16 font-normal text-808080'>
						{moment(item?.created_at, "YYYY-MM-DD").format(
							"DD MMM, YYYY"
						)}
					</p>

					<span
						className='text-12 sm:text-16 font-normal text-delivered underline'
						href={`#!`}>
						Read more
					</span>
				</div>
			</div>
		</div>
	);
}
