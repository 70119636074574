import { createSlice } from "@reduxjs/toolkit";

export const paymentMethodSlice = createSlice({
	name: "paymentMethod",
	initialState: {
		deliveryDate: "",
		paymentMethod: "",
		paymentTerm: "",
		creditFee: 0,
		paymentTermText: "",
		paymentName: "",
		paymentMethodText: "",
	},
	reducers: {
		paymentData: (state) => {
			return state;
		},
		deliveryDate: (state, action) => {
			state.deliveryDate = action.payload;
		},
		paymentMethod: (state, action) => {
			state.paymentMethod = action.payload;
		},
		paymentTerm: (state, action) => {
			state.paymentTerm = action.payload;
		},
		setPaymentName: (state, action) => {
			state.paymentName = action.payload;
		},
		creditFee: (state, action) => {
			state.creditFee = action.payload;
		},
		setPaymentMethodText: (state, action) => {
			state.paymentMethodText = action.payload;
		},
		clearPaymentCard: (state, action) => {
			state.deliveryDate = "";
			state.paymentMethod = "";
			state.paymentTerm = "";
			state.creditFee = 0;
			state.paymentTermText = "";
			state.paymentName = "";
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	deliveryDate,
	paymentMethod,
	paymentTerm,
	creditFee,
	clearPaymentCard,
	setPaymentName,
	setPaymentMethodText,
} = paymentMethodSlice.actions;
export const { actions, reducer } = paymentMethodSlice;

export default reducer;
