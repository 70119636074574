import { useSelector } from "react-redux";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Slider from "react-slick";
import "./style.scss";
import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { IonLoading } from "@ionic/react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import SectionHeader from "./SectionHeader";

export default function BazAiVideos() {
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const [playing1, setPlaying1] = useState(true);
  const [playing2, setPlaying2] = useState(true);
  const [playing3, setPlaying3] = useState(true);
  const [loading, setLoading] = useState(false);
  const [mainLoading, setMainLoading] = useState(true);
  const settings = {
    dots: true,
    dotsClass: "slick-dots customer custom-slick-dots",
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // centerMode: true,
    adaptiveHeight: true,
    appendDots: (dots) => <ul onClick={videoLoading}>{dots}</ul>,
  };
  const sliderRef = useRef();
  const next = () => {
    videoLoading();
    sliderRef.current?.slickNext();
  };

  const previous = () => {
    videoLoading();
    sliderRef.current?.slickPrev();
  };

  const videoLoading = () => {
    setLoading(true);
    let interval = setTimeout(() => {
      clearTimeout(interval);
      setLoading(false);
    }, 400);
  };
  useEffect(() => {
    let interval = setTimeout(() => {
      clearTimeout(interval);
      setMainLoading(false);
    }, 2000);
    return () => clearTimeout(interval);
  }, []);

  return (
    <div className="container m-auto bg-F2F3F7 customer-impact pt-[50px] lg:pt-0 pb-[0px] md:pb-[70px] lg:pb-[50px] ">
      {/* <SectionHeader title={ekkLanguage.landing.impact} /> */}

      <div className="relative customer-slider">
        <div className="customer-slider-wrapper relative">
          {mainLoading ? (
            <SkeletonTheme baseColor="#fff" highlightColor="#edebeb">
              <div className="slider-loader">
                <Skeleton height="70%" />
                <Skeleton height="20" />
                <Skeleton height="20" />
              </div>
            </SkeletonTheme>
          ) : (
            <Slider
              {...settings}
              ref={sliderRef}
              onSwipe={() => videoLoading()}
            >
              <div>
                {loading ? (
                  <SkeletonTheme baseColor="#fff" highlightColor="#edebeb">
                    <div className="slider-loader">
                      <Skeleton height="70%" />
                      <Skeleton height="20" />
                      <Skeleton height="20" />
                    </div>
                  </SkeletonTheme>
                ) : (
                  <>
                    <div className="player-wrapper">
                      <ReactPlayer
                        className="react-player"
                        url="https://ekkfilesdbt.blob.core.windows.net/userssblobs/629f60e6-cc9b-474e-ad65-3647a9c0dd54baz_ai_enterprise.mp4"
                        width="100%"
                        height="518"
                        controls={true}
                        onContextMenu={(e) => e.preventDefault()}
                        light={
                          <img
                            src="/assets/video/baz_ai_enterprise.png"
                            alt="Thumbnail"
                          />
                        }
                        // config={{
                        // 	file: {
                        // 		attributes: {
                        // 			controlsList: "nofullscreen",
                        // 		},
                        // 	},
                        // }}
                        playIcon={
                          <button>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="90"
                              height="90"
                              viewBox="0 0 90 90"
                              fill="none"
                            >
                              <circle
                                cx="45"
                                cy="45"
                                r="43.75"
                                stroke="black"
                                strokeWidth="2.5"
                              />
                              <path
                                d="M61 43.2679C62.3333 44.0377 62.3333 45.9622 61 46.732L38.5 59.7224C37.1667 60.4922 35.5 59.53 35.5 57.9904L35.5 32.0096C35.5 30.47 37.1667 29.5078 38.5 30.2776L61 43.2679Z"
                                fill="black"
                              />
                            </svg>
                          </button>
                        }
                        playing={playing1}
                      />
                    </div>
                    <p className="text-center pt-[25px] md:px-10 px-0 font-normal md:text-20 text-12 text-black-500">
                      <b>For Enterprise</b>
                    </p>
                  </>
                )}
              </div>
              <div>
                {loading ? (
                  <SkeletonTheme baseColor="#fff" highlightColor="#edebeb">
                    <div className="slider-loader">
                      <Skeleton height="70%" />
                      <Skeleton height="20" />
                      <Skeleton height="20" />
                    </div>
                  </SkeletonTheme>
                ) : (
                  <>
                    {" "}
                    <div className="player-wrapper">
                      <ReactPlayer
                        className="react-player"
                        url="https://ekkfilesdbt.blob.core.windows.net/userssblobs/af2f5060-4bc1-45ee-8859-6b69531a5354baz_ai_smb.mp4"
                        width="100%"
                        height="518"
                        controls={true}
                        // config={{
                        // 	file: {
                        // 		attributes: {
                        // 			controlsList: "nofullscreen",
                        // 		},
                        // 	},
                        // }}
                        light={
                          <img
                            src="/assets/video/baz_ai_smb.png"
                            alt="Thumbnail"
                          />
                        }
                        playIcon={
                          <button>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="90"
                              height="90"
                              viewBox="0 0 90 90"
                              fill="none"
                            >
                              <circle
                                cx="45"
                                cy="45"
                                r="43.75"
                                stroke="white"
                                strokeWidth="2.5"
                              />
                              <path
                                d="M61 43.2679C62.3333 44.0377 62.3333 45.9622 61 46.732L38.5 59.7224C37.1667 60.4922 35.5 59.53 35.5 57.9904L35.5 32.0096C35.5 30.47 37.1667 29.5078 38.5 30.2776L61 43.2679Z"
                                fill="white"
                              />
                            </svg>
                          </button>
                        }
                        playing={playing2}
                      />
                    </div>
                    <p className="text-center pt-[25px] md:px-10 px-0 font-normal md:text-20 text-12  text-black-500">
                      <b>For SMB</b>
                    </p>
                  </>
                )}
              </div>
              {/* <div>
                {loading ? (
                  <SkeletonTheme baseColor="#fff" highlightColor="#edebeb">
                    <div className="slider-loader">
                      <Skeleton height="70%" />
                      <Skeleton height="20" />
                      <Skeleton height="20" />
                    </div>
                  </SkeletonTheme>
                ) : (
                  <>
                    {" "}
                    <div className="player-wrapper">
                      <ReactPlayer
                        className="react-player"
                        url="https://ekkfilesdbt.blob.core.windows.net/userssblobs/70a522a7-76ac-4b5d-8e0c-eae566e81e598.mp4"
                        width="100%"
                        height="518"
                        controls={true}
                        // config={{
                        // 	file: {
                        // 		attributes: {
                        // 			controlsList: "nofullscreen",
                        // 		},
                        // 	},
                        // }}
                        light={
                          <img src="/assets/video/3.png" alt="Thumbnail" />
                        }
                        playIcon={
                          <button>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="90"
                              height="90"
                              viewBox="0 0 90 90"
                              fill="none"
                            >
                              <circle
                                cx="45"
                                cy="45"
                                r="43.75"
                                stroke="white"
                                strokeWidth="2.5"
                              />
                              <path
                                d="M61 43.2679C62.3333 44.0377 62.3333 45.9622 61 46.732L38.5 59.7224C37.1667 60.4922 35.5 59.53 35.5 57.9904L35.5 32.0096C35.5 30.47 37.1667 29.5078 38.5 30.2776L61 43.2679Z"
                                fill="white"
                              />
                            </svg>
                          </button>
                        }
                        playing={playing3}
                      />
                    </div>
                    <p className="text-center pt-[25px] md:px-10 px-0 font-normal md:text-20 text-12 text-black-500">
                      <b>Sustainable Development: </b>
                      Greater access to knowledge, tooling and resources to
                      operate environmentally sustainable businesses.
                    </p>
                  </>
                )}
              </div> */}
            </Slider>
          )}
        </div>

        <div className="arrows absolute left-0" onClick={previous}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="70"
            height="70"
            viewBox="0 0 70 70"
            fill="none"
          >
            <circle
              cx="35"
              cy="35"
              r="33.75"
              stroke="#797A7C"
              strokeWidth="2.5"
            />
            <path
              d="M22.0833 35.4574L47.0833 35.4574"
              stroke="#797A7C"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M32.1663 45.4979L22.083 35.4579L32.1663 25.4163"
              stroke="#797A7C"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="arrows absolute right-0" onClick={next}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="70"
            height="70"
            viewBox="0 0 70 70"
            fill="none"
          >
            <circle
              cx="35"
              cy="35"
              r="33.75"
              stroke="#797A7C"
              strokeWidth="2.5"
            />
            <path
              d="M47.9167 34.5426H22.9167"
              stroke="#797A7C"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M37.8337 24.5021L47.917 34.5421L37.8337 44.5837"
              stroke="#797A7C"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}
