import Menu from "assets/images/icon/svg/Menu";
import { Link } from "react-router-dom";
import "./style.scss";
import Cross from "assets/images/icon/svg/Cross";
import { useState } from "react";
import CustomNavigateSelect from "./CustomNavigateSelect";

export default function BazAINavigation({ scrolling, from }) {
	const [showMenu, setShowMenu] = useState(false);
	const industryOptions = [
		{ label: "Consumer Goods", path: "/industry/c-goods" },
		{ label: "Electronics", path: "/industry/electronics" },
		{ label: "Film", path: "/industry/film" },
		{ label: "Healthcare", path: "/industry/healthcare" },
		{ label: "Hotel", path: "/industry/hotel" },
	];
	const companyOptions = [
		{ label: "About us", path: "/company/about-us" },
		{ label: "Our Impact", path: "/company/our-impact" },
		{ label: "Our News", path: "/company/our-news" },
		{ label: "Contact us", path: "/company/contact-us" },
	];
	const productsOptions = [
		{ label: "AR Automation", path: "/product/ar-automation" },
		{ label: "B2B Marketplace", path: "/product/b2b" },
		{ label: "Customer Management", path: "/product/customer" },
		{ label: "Delivery Management", path: "/product/delivery" },
		{ label: "Distributor Management", path: "/product/distributor" },
		{ label: "Embedded Financing", path: "/product/financing" },
		{ label: "Inventory Management", path: "/product/inventory" },
		{ label: "Salesforce Automation", path: "/product/salesforce" },
		{ label: "Supplier Management", path: "/product/supplier" },
		{ label: "Warehouse Management", path: "/product/warehouse" },
	];

	return (
		<>
			<div>
				<div className={`fixed z-50 w-full bg-white`}>
					{/* Navigation for web */}
					<div
						className={`container hidden lg:flex py-3 ${
							scrolling ? "bg-white" : "lg:bg-transparent bg-white"
						}`}>
						<div>
							<Link to='/'>
								<img
									src={
										from === "mainLanding"
											? "/assets/images/LogoAI.png"
											: "/assets/images/LogoAI.png"
									}
									alt='logo'
									className='w-[145px] mr-10 xl:mr-20'
								/>
							</Link>
						</div>
						<div className='flex items-center justify-between w-full'>
							<ul className='flex items-center header-menu-items'>
								<li>
									<CustomNavigateSelect
										options={productsOptions}
										title='Products'
										width='250px'
									/>
								</li>
								<li>
									<CustomNavigateSelect
										options={industryOptions}
										title='Industry'
									/>
								</li>
								<li>
									<CustomNavigateSelect
										options={companyOptions}
										title='Company'
									/>
								</li>
							</ul>
							<ul className='flex items-center'>
								<li className='flex items-center  xl:pr-8 pr-2'>
									<a
										href='https://wa.me/+6592344538'
										className='text-18  font-bold xl:pl-2 pl-2'
										target='_blank'
										rel='noreferrer'>
										<img
											className='h-[24px]'
											src='/assets/images/whatsapp.png'
											alt=''
										/>
									</a>
								</li>
							</ul>
						</div>
					</div>
					{/* Navigation for mobile devices */}
					<div className={`lg:hidden`}>
						<div
							className={`${
								scrolling ? "mt-0 py-2" : "mt-0 "
							} justify-between flex lg:hidden py-3 lg:py-0 bg-white`}>
							<div>
								<Link to='/'>
									<img
										src={
											from === "mainLanding"
												? "/assets/images/LogoAI.png"
												: "/assets/images/LogoAI.png"
										}
										alt='logo'
										className='sm:w-[145px] w-[100px] mr-[80px] pl-2'
									/>
								</Link>
							</div>
							<div className='flex items-center  xl:pr-8 pr-2'>
								<a
									// href="https://wa.me/message/IYU5JYR6ZJSKH1"
									href='https://wa.me/+6592344538'
									className='text-18 font-bold xl:pl-2 pl-2'
									target='_blank'
									rel='noreferrer'>
									<img
										className='h-[24px]'
										src='/assets/images/whatsapp.png'
										alt=''
									/>
								</a>
							</div>
							<div className='flex items-center pr-2'>
								<div onClick={() => setShowMenu(!showMenu)}>
									{showMenu ? (
										<Cross color={`#000000`} />
									) : (
										<Menu color={`#000000`} />
									)}
								</div>
							</div>
						</div>
						{/* Menu's div for mobile versions  */}
						<div
							className={`bg-white z-50 lg:top-[80px] right-5 p-[20px] rounded-[5px] transition-all ${
								showMenu ? "absolute" : "hidden"
							}`}>
							<div className='py-1'>
								<ul>
									<li className='mr-4 xl:mr-5'>
										<CustomNavigateSelect
											options={productsOptions}
											title='Products'
											width='250px'
										/>
									</li>
									<li className='mr-4 xl:mr-5'>
										<CustomNavigateSelect
											options={industryOptions}
											title='Industry'
										/>
									</li>
									<li className='mr-4 xl:mr-5'>
										<CustomNavigateSelect
											options={companyOptions}
											title='Company'
										/>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
