import React from "react";
import { useSelector } from "react-redux";

export default function BannerContent() {
	const ekkLanguage = useSelector((state) => state.lan);

	return (
		<div className='pt-[200px] lg:pt-[180px] lg:pb-[0px] pb-0'>
			<h1
				className={`lg:text-44 md:text-40 text-24  font-extrabold  text-center flex flex-wrap justify-center md:px-[50px] lg:px-[100px] mb-5 text-white`}>
				Transform Your Procurement Team with Superhuman AI Agents.
			</h1>
			{/* <h1
				className={`lg:text-44 md:text-40 text-24  font-extrabold text-262626-1000 text-center flex flex-wrap justify-center`}>
				{ekkLanguage?.lan.landing.header2}
			</h1> */}
			<p className='text-center md:px-[100px]  lg:px-[100px] font-medium lg:text-22 text-14 text-white'>
				Baz AI is the groundbreaking solution that transforms procurement
				teams into a strategic powerhouse with limitless AI efficiency. Our
				AI agents are designed to streamline every aspect of your
				procurement workflows by 95%, enabling teams to operate at peak
				performance 24/7 while delivering exceptional value.
			</p>
		</div>
	);
}
