export const salesForceBenefits = [
  {
    id: 1,
    tabTitle: "Connect and Expand Network",
    headline: "Connect and Expand Network",
    description:
      "Effortlessly connect with a vast network of buyers and suppliers to streamline your sales processes. Expand your business reach and build strong partnerships by leveraging a large, diverse network. Enhance collaboration and deal-making with nearby businesses through dynamic pricing strategies.",
    image: "/assets/images/salesforce-1.png",
  },
  {
    id: 2,
    tabTitle: "Simplified Order Tracking",
    headline: "Simplified Order Tracking",
    description:
      "Track daily orders with ease and precision, ensuring you know exactly how much has been ordered each day. Assign drivers and collectors for efficient order delivery and collection, optimizing logistics and improving customer satisfaction.",
    image: "/assets/images/salesforce-2.png",
  },
  {
    id: 3,
    tabTitle: "AI-Driven Features for Optimal Sales",
    headline: "AI-Driven Features for Optimal Sales",
    description:
      "Utilize AI-powered voice and image ordering systems to streamline the order placement process. Benefit from price analysis across different suppliers, allowing you to identify and secure the best deals.",
    image: "/assets/images/salesforce-3.png",
  },
];
