import { IonButton } from "@ionic/react";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function ErrorPage() {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex items-center justify-center h-screen">
        <div className="text-center">
          <h4 className="text-18 text-black-1000 font-bold pb-6">
            No Content found
          </h4>
          <IonButton
            color="primary"
            className="font-bold h-10 text-14"
            onClick={() => {
              navigate("/");
            }}
          >
            Back To Home page
          </IonButton>
        </div>
      </div>
    </>
  );
}
