export const salesData = [
	{
		id: 1,
		tabTitle: "Seamless Delivery Management",
		userType: "Expand Your Sales Network",
		description:
			"Seamlessly connect with a broad network of buyers and suppliers. Enhance your reach and forge strong partnerships, driving collaboration and dynamic pricing strategies with nearby businesses.",
		banner: "/assets/images/products/sales1.png",
	},
	{
		id: 2,
		tabTitle: "Real Time Order Management",
		userType: "Simplified Order Management",
		description:
			"Track daily orders with precision. Assign drivers and collectors to streamline delivery and collection, optimizing logistics and elevating customer satisfaction.",
		banner: "/assets/images/products/sales2.png",
	},
	{
		id: 3,
		tabTitle: "AI-Driven Delivery Optimization",
		userType: "AI-Powered Sales Optimization",
		description:
			"Transform the order placement process with AI-driven voice and image ordering systems. Gain competitive pricing insights from multiple suppliers, securing the best deals.",
		banner: "/assets/images/products/sales3.png",
	},
];
