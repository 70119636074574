export const filmFacilites = [
  {
    id: 1,
    userType: "Film Producers",
    description: {
      line1:
        "Are you in the market for filmmaking equipment? Do you need financing to rent equipment for your production?",
      line2:
        "Sign up to learn how to manage your cashflow with embedded finance solutions.",
    },
    btnLabel: "Sign Up",
    // formLink: "https://forms.gle/G6H4VzgS97iQUmWz9",
    formLink: "https://ekkbaz.com/",
    demoLink:
      "https://outlook.office365.com/book/AIAgentforBusiness@EkkBaz.com/",
    demoBtnLabel: "Demo",
    banner: "/assets/images/producer_banner.png",
  },
  {
    id: 2,
    userType: "Film Equipment Suppliers",
    description: {
      line1:
        "Do you supply equipment for filming? Do you need financing supports to procure your filming equipment?",
      line2: "Sign up to find out how can grow your business by 50%.",
    },
    btnLabel: "Sign Up",
    // formLink: "https://forms.gle/G3fhPuDfPRhnxDre8",
    formLink: "https://seller.ekkbaz.com/",
    demoLink:
      "https://outlook.office365.com/book/AIAgentforBusiness@EkkBaz.com/",
    demoBtnLabel: "Demo",
    banner: "/assets/images/suppler_banner.png",
  },

  {
    id: 3,
    userType: "Finance Providers",
    description: {
      line1:
        "Are you a financial services company? Do you offer B2B inventory financing?",
      line2:
        "Book an appointment to find out how you can increase adoption of your embedded finance services.",
    },
    btnLabel: "Book an Appointment",
    // formLink: "https://forms.gle/NBe2Q8JM3qJeXhQJ9",
    formLink:
      "https://outlook.office365.com/book/AIAgentforBusiness@EkkBaz.com/",
    banner: "/assets/images/money.jpeg",
  },
];
